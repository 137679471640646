<template>
  <div>
    <a @click="gotoProcesso(processo)"><strong>{{processo.label}}</strong></a>
  </div>
</template>

<script>

import globalProcessoMixin from "@/components/processos/globalProcessoMixin"
import relationMixin from "@/components/sltarefas/components/inputs/relationMixin"

export default {
  name: "TaskProcessoInput",
  mixins: [globalProcessoMixin, relationMixin],
  components: {},
  data () {
    return {
      processo: this.value
    }
  },
  mounted() {
  },
  computed: {
  }
}
</script>
