<template>
  <div>
    <a @click="gotoBem(bem)"><strong>{{bem.label}}</strong></a>
  </div>
</template>

<script>

import relationMixin from "@/components/sltarefas/components/inputs/relationMixin"
import globalBemMixin from "@/components/bem/globalBemMixin"

export default {
  name: "TaskBemInput",
  mixins: [globalBemMixin, relationMixin],
  components: {},
  data () {
    return {
      bem: this.value
    }
  },
  mounted() {
  },
  computed: {
  }
}
</script>
