<script>
    import {UPopover} from 'uloc-vue'
    import GlobalPersonMixin from "@/components/pessoa/globalPersonMixin"
    export default {
      name: 'popover-user-profile',
      inject: ['container'],
      mixins: [GlobalPersonMixin],
      props: {
        task: {
          required: false
        },
        user: Object,
        anchor: {
          type: String,
          default: 'bottom middle'
        },
        self: {
          type: String,
          default: 'top middle'
        },
        isFollowComponent: {
          type: Boolean,
          default: false
        }
      },
      data () {
        return {}
      },
      computed: {
        '$router' () {
          return this.container.$router
        }
      },
      mounted () {
      },
      destroyed () {
      },
      methods: {},
      components: {
        UPopover
      }
    }
</script>

<template>
    <u-popover class="popover-user-profile" :anchor="anchor" :self="self" :offset="[10, 10]">
      <div class="name"><a @click="gotoPerson(user.person || user)">{{ user.person ? user.person.name : user.name }}</a></div>
      <div v-if="user.user" class="username">@{{ user.user.username }}</div>
      <div v-if="!isFollowComponent && (!task || !task.id)" class="actions m-t"><u-btn @click="$emit('remove', user)" size="sm" color="negative" no-caps>Remover</u-btn></div>
    </u-popover>
</template>
