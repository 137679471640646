export const task = {
    id: null,
    description: null,
    text: '',
    startTime: null,
    deadline: null,
    internalStatus: null,
    labels: null,
    expectedHours: '00:00',
    workedHours: '00:00',
    priority: 2,
    type: null,
    status: null,
    comments: null,
    author: null,
    assigned: null,
    attachments: null,
    participants: null,
    followers: null,
    tags: [],
    works: null,
    project: null,
    checklists: null,
    cliente: null,
    queue: null,
    entidade: null,
    entidadeId: null
}

