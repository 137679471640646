<script>
    export default {
      name: 'task-priority',
      props: ['priority'],
      data () {
        return {}
      },
      computed: {},
      mounted () {
      },
      destroyed () {
      },
      methods: {},
      components: {}
    }
</script>

<template>
    <span>
        <u-icon v-if="priority === 'low'" title="Baixa" name="arrow-alt-down" type="fa" icon-style="solid" color="grey-5"></u-icon>
        <u-icon v-if="priority === 'normal'" title="Nomal" name="square" type="fa" icon-style="solid" color="black"></u-icon>
        <u-icon v-if="priority === 'high'" title="Alta" name="arrow-alt-up" type="fa" icon-style="solid" color="danger"></u-icon>
        <u-icon v-if="priority === 'urgent'" title="Urgente" name="exclamation-triangle" type="fa" icon-style="solid" color="danger"></u-icon>
    </span>
</template>
