<script>
    import {UWindowHeaderExtButtons, UTooltip, event} from 'uloc-vue'
    import TaskMenuOptions from "./context/context-window-options"
    import CopyToClipboard from "@/utils/copy-to-clipboard"
    export default {
      inject: ['container'],
      name: 'buttons-task-window',
      props: ['task'],
      // mixins: [UWindowHeaderExtButtons],
      data () {
        return {}
      },
      computed: {},
      methods: {
        getTaskUrl () {
          const $router = this.$router || this.container.$router
          const route =  $router.resolve({name: 'task.show', params: {id: this.task.id}})
          return location.protocol + '//' + location.hostname + (location.port ? (':' + location.port) : '') + '/' + route.href
        },
        copyTaskUrl (evt) {
          const url = this.getTaskUrl()
          event.stopAndPrevent(evt)
          CopyToClipboard(url)
              .then(() => {
                this.$uloc.notify({color: 'positive', icon: 'done', message: 'O link da tarefa foi copiado em sua área de transferência.'})
              })
              .catch(() => {
                this.$uloc.notify({
                  color: 'negative',
                  message: `Erro ao tentar copiar a URL!`,
                  position: 'bottom-left',
                })
              })
        },
        openTask () {
          const $router = this.$router || this.container.$router
          const route =  $router.resolve({name: 'task.show', params: {id: this.task.id}})
          this.$root && this.$root.wid && this.$uloc.window.close(this.$root.wid)
          window.open(route.href, '_blank')
        }
      },
      components: {UTooltip, TaskMenuOptions}
    }
</script>

<template>
    <div class="task-ext-btns inline m-r">
        <a @click="copyTaskUrl" class="m-l">
            <u-icon name="link" type="fa" icon-style="light"/>
            <u-tooltip :offset="[4,4]">Copiar link da tarefa</u-tooltip>
        </a>
        <!--<a class="m-l">
            <u-icon name="thumbs-up" type="fa" icon-style="light"/>
            <u-tooltip :offset="[4,4]">Curtir isto</u-tooltip>
        </a>-->
        <a class="m-l">
            <span style=""><u-icon name="ellipsis-h" type="fa" icon-style="solid"/></span>
            <task-menu-options @openTask="openTask" :task="task"/>
            <u-tooltip :offset="[4,4]">Mais opções</u-tooltip>
        </a>
    </div>
</template>
