<script>
    export default {
      name: 'task-tabs-tab',
      inject: {
        containerTab: {
          default () {
            console.error('Task-Tabs-Tab needs to be child of Task-Tabs')
          }
        }
      },
      props: {
        name: String,
        icon: String,
        label: String,
        active: {
          type: Boolean,
          default: false
        },
      },
      data () {
        return {
        }
      },
      computed: {
        bindClass () {
          let css = []
          if (this.containerTab.activeTab === this.name) {
            css.push('active')
          }
          return css
        }
      },
      mounted () {
      },
      destroyed () {
      },
      methods: {
        click (e) {
          this.$emit('click', e)
          this.containerTab.click(this.name, e)
        }
      },
      components: {}
    }
</script>

<template>
    <li class="tabdrop non-selectable" :class="bindClass">
        <a @click="click" class="tab-trigger" data-target="comments">
            <u-icon :name="icon" type="fa" icon-style="light"/>
            <span>{{label}}</span>
        </a>
    </li>
</template>
