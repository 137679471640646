<template>
  <div>
    <a @click="gotoPerson(pessoa)"><strong>{{pessoa.label || pessoa.name}}</strong></a>
  </div>
</template>

<script>

import globalPersonMixin from "@/components/pessoa/globalPersonMixin"
import relationMixin from "@/components/sltarefas/components/inputs/relationMixin"

export default {
  name: "TaskRelationPessoaInput",
  mixins: [globalPersonMixin, relationMixin],
  components: {},
  data () {
    return {
      pessoa: this.value
    }
  },
  mounted() {
  },
  computed: {
  }
}
</script>
