<script>
    export default {
      name: 'task-row',
      props: {
        col: {
          type: Boolean,
          default: false
        },
        label: String,
        labelHelper: String
      },
      data () {
        return {}
      },
      computed: {},
      mounted () {
      },
      destroyed () {
      },
      methods: {},
      components: {}
    }
</script>

<template>
    <div :class="col ? 'task-row-col' : 'task-row'">
        <slot name="label">
            <label>
                {{label}}
                <u-icon v-if="labelHelper" name="question-circle" type="fa" color="grey-5" class="fa-fw helper">
                    <u-tooltip :offset="[10,10]">{{labelHelper}}</u-tooltip>
                </u-icon>
            </label>
        </slot>
        <slot name="content-main">
            <div class="content">
                <slot></slot>
            </div>
        </slot>
    </div>
</template>
