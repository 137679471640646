<script>
import {UPopover} from "uloc-vue";
import TaskMenuOptionsItem from "./context-window-options-item";

export default {
  name: 'task-menu-options',
  inject: ['container'],
  props: ['task'],
  components: {TaskMenuOptionsItem, UPopover},
  methods: {
    deleteTask (evt) {
      this.container.deleteTask()
    },
    editTask (evt) {
      this.container.startEditing()
    },
    finishTask (evt) {
      this.container.finish()
    },
    openTask (evt) {
      console.log('Open Task Event')
      this.$emit('openTask')
    }
  }
}
</script>

<template>
    <u-popover class="window-context-menu" anchor="bottom right" self="top right">
        <div class="menu">
            <ul>
                <task-menu-options-item v-shortkey="['ctrl', 'shift', 'o']" @shortkey.native="openTask" @click="openTask" close label="Abrir tarefa em nova janela" shortcut="CTRL+SHIFT+O"/>
                <task-menu-options-item @click="editTask" close label="Editar tarefa" shortcut="SHIFT+E"/>
                <task-menu-options-item @click="finishTask" close label="Concluir tarefa" shortcut="CTRL+SHIFT+C"/>
                <task-menu-options-item @click="deleteTask" close label="Excluir tarefa" shortcut="TAB+DEL"/>
                <task-menu-options-item disable close label="Adicionar à reunião" shortcut="TAB+R"/>
                <task-menu-options-item disable close label="Adicionar ao projeto" shortcut="TAB+P"/>
                <task-menu-options-item disable close label="Duplicar"/>
                <li class="separator"></li>
                <task-menu-options-item disable close label="Imprimir"/>
            </ul>
        </div>
    </u-popover>
</template>
