<script>
import {followTask, unfollowTask} from "@/domain/tarefas/services"

    export default {
      name: 'follow-task',
      props: ['task'],
      data () {
        return {
          active: false
        }
      },
      computed: {
        me () {
          return this.$uloc.auth.session.user.person
        },
        isSeguindo() {
          if (!this.task.followers || !this.task.followers.length) {
            return false
          }
          let find = this.task.followers.find(s => s.person.id === Number(this.me))
          return find
        },
      },
      mounted () {
      },
      destroyed () {
      },
      methods: {
        follow () {
          followTask(this.task)
          .then(response => {
            this.$emit('followersUpdate', response.data)
            this.task.followers = response.data
          })
          .catch(error => {
            this.alertApiError(error)
          })
        },
        unfollow () {
          unfollowTask(this.task)
          .then(response => {
            this.$emit('followersUpdate', response.data)
            this.task.followers = response.data
          })
          .catch(error => {
            this.alertApiError(error)
          })
        }
      },
      components: {}
    }
</script>

<template>
    <a @click="() => isSeguindo ? unfollow() : follow()" :class="{active: active, 'link-black': !isSeguindo}"><u-icon name="bell" type="fa" class="m-r-xs" />
        <span v-if="!isSeguindo">Seguir tarefa</span>
        <span v-else>Seguindo</span>
    </a>
</template>
