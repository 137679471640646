<script>
    import TaskTabsTab from "./task-tabs-tab";
    import LoadingComments from './tabs/comments-loading'

    /*const tmpComponents = {
      'comments': import('./tabs/comments.vue')
    }

    let tmpLoad = (component) => new Promise((resolve, reject) => {

      setTimeout(function () {
        // Passa a definição do componente para resolver o callback
        resolve(tmpComponents[component])
      }, 4000)
    })*/

    /* TABS */
    const Comments = () => ({
      component: import('./tabs/comments.vue'),
      loading: LoadingComments,
      error: LoadingComments,
      delay: 200,
      timeout: 5000
    })

    const Attachments = () => ({
      component: import('./tabs/attachments.vue'),
      loading: LoadingComments,
      error: LoadingComments,
      delay: 200,
      timeout: 5000
    })

    const Followers = () => ({
      component: import('./tabs/followers.vue'),
      loading: LoadingComments,
      error: LoadingComments,
      delay: 200,
      timeout: 5000
    })

    const Rules = () => ({
      component: import('./tabs/rules.vue'),
      loading: LoadingComments,
      error: LoadingComments,
      delay: 200,
      timeout: 5000
    })

    const Checklist = () => ({
      component: import('./tabs/checklist.vue'),
      loading: LoadingComments,
      error: LoadingComments,
      delay: 200,
      timeout: 5000
    })

    export default {
      name: 'task-tabs',
      props: {
        task: {
          required: true
        }
      },
      provide () {
        return {
          containerTab: this
        }
      },
      data () {
        return {
          activeTab: 'comments',
          isDescMin: false
        }
      },
      computed: {
        tabComponent () {
          const components = {
            'comments': Comments,
            'attachments': Attachments,
            'followers': Followers,
            'rules': Rules,
            'checklist': Checklist
          }
          return components[this.activeTab]
        }
      },
      mounted () {
      },
      destroyed () {
      },
      methods: {
        click (name) {
          this.activeTab = name
        }
      },
      components: {TaskTabsTab}
    }
</script>

<template>
  <div v-if="task.id" class="task-content flex content-between" :class="{min: isDescMin}">
    <div id="tab-list" class="tab-list flex content-between column col-grow-1 w-full">
        <div class="flex flex-zero content-between tab-menu">
            <ul class="nav nav-tabs flat-nav-tabs w-full relative-position">
                <task-tabs-tab name="comments" icon="comments-alt" label="Comentários"/>
                <task-tabs-tab name="attachments" icon="paperclip" label="Anexos"/>
                <!--<task-tabs-tab name="followers" icon="rss" label="Seguidores"/>-->
                <task-tabs-tab name="rules" icon="code-branch" label="Regras"/>
                <task-tabs-tab name="checklist" icon="clipboard-list-check" label="Checklist"/>
              <u-icon @click.native="isDescMin = !isDescMin" :name="!isDescMin ? 'minus-square' : 'expand'" class="cursor-pointer m-r-xxl absolute-right" type="fa" color="blue-grey-3">
                <u-tooltip :offset="[5,5]">{{!isDescMin ? 'Ocultar' : 'Restaurar'}}</u-tooltip>
              </u-icon>
            </ul>
        </div>
        <div class="flex col-grow-1 w-full tab-content">
            <component v-show="!isDescMin" v-bind:is="tabComponent" :task="task"></component>
        </div>
    </div>
  </div>
</template>
