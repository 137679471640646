<script>
import {UTooltip, UPopover} from 'uloc-vue'
import PopoverUser from "./popover-user"
import PopoverUserProfile from "./popover-user-profile"
import PessoaInput from "@/components/sltarefas/components/inputs/PessoaInput"
import GlobalPersonMixin from "@/components/pessoa/globalPersonMixin"
import {updateTask} from "@/domain/tarefas/services"

export default {
  name: 'task-users',
  mixins: [GlobalPersonMixin],
  props: {
    users: Array,
    placeholder: String,
    disableProfile: {
      type: Boolean,
      default: false
    },
    disableUserTask: {
      type: Boolean,
      default: false
    },
    disableAddUser: {
      type: Boolean,
      default: false
    },
    isFollowComponent: {
      type: Boolean,
      default: false
    },
    isUserComponent: {
      type: Boolean,
      default: false
    },
    task: Object
  },
  data() {
    return {}
  },
  computed: {
    usersFiltered () {
      if (!Array.isArray(this.users)) {
        return null
      }
      return this.users.filter(u => u.active)
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    openSearch() {
      this.$refs.tooltip.hide()
      setTimeout(() => {
        this.$refs.input.open()
      }, 100)
    },
    add(user) {
      let addfcn = () => {
        if (!Array.isArray(this.task.participants)) {
          this.task.participants = []
        }
        if (!this.task.participants.find(p => {
          return p.person && p.person.id === user.person.id
        })) {
          this.task.participants.push({
            user: user,
            person: user.person,
            active: true
          })
        }
      }
      console.log(user)
      !this.task.id && addfcn()
      this.task.id && this.$uloc.dialog({
        title: 'Alocar responsável de tarefa',
        message: `Tem certeza que deseja adicionar ${user.person.name} como responsável desta tarefa ?`,
        noCaps: true,
        ok: `Sim, adicionar`,
        cancel: 'Não'
      })
          .then(() => {
            console.log('Add user', user)
            addfcn()
            this.update()
          })
          .catch(() => {

          })
    },
    remove(user) {
      !this.task.id && this.task.participants.splice(this.task.participants.indexOf(user), 1)
      this.task.id && this.$uloc.dialog({
        title: 'Remover responsável de tarefa',
        message: `Tem certeza que deseja remover ${user.person.name} desta tarefa ?`,
        noCaps: true,
        ok: `Sim, remover`,
        cancel: 'Não'
      })
          .then(() => {
            console.log('Remove')
            this.task.participants.splice(this.task.participants.indexOf(user), 1)
            this.update()
          })
          .catch(() => {

          })
    },
    update() {
      this.$nextTick(() => {
        const task = {
          id: this.task.id,
          participants: this.task.participants.map(p => {
            return {
              person: p.person.id,
              user: p.user ? p.user.id : null,
              active: true
            }
          })
        }
        updateTask(task, 'PATCH')
            .then(response => {
              console.log(response.data)
              this.task.participants = response.data.participants
            })
            .catch(error => {
              this.alertApiError(error)
            })
      })
    }
  },
  components: {
    PessoaInput,
    PopoverUserProfile,
    PopoverUser,
    UTooltip,
    UPopover
  }
}
</script>

<template>
  <div class="task-users flex items-center">
        <span class="inline relative-position cursor-pointer" v-bind:key="user.id" v-for="user in usersFiltered">
            <div class="person-avatar avatar inline m-l-sm hover-scale">
              <img v-if="user.person.photo" width="30" class="" :src="user.person.photo"/>
              <div v-else class="img-fake">{{ firstLettersName(user.person.name) }}</div>
            </div>
            <popover-user v-if="!disableUserTask && task && task.id" :user="user" :task="task" @remove="remove"/>
            <popover-user-profile :is-follow-component="isFollowComponent" @remove="remove"
                                  v-if="!task.id || isFollowComponent" :user="user"/>
          <!--<u-tooltip anchor="bottom middle" self="top middle"
                  :offset="[10, 10]">{{user.nome}}{{user.sublabel ? ' (' + user.sublabel + ')' : ''}}</u-tooltip>-->
        </span>
    <span class="inline relative-position cursor-pointer m-l-sm" v-if="task.id && (!users || !users.length)">
          {{ placeholder }}
        </span>
    <a v-if="!disableAddUser" class="add-new-user m-l-sm items-center justify-center hover-scale" @click="openSearch">
      <u-icon name="plus" type="fa" icon-style="light"/>
      <u-tooltip ref="tooltip" anchor="bottom middle" self="top middle" :offset="[10, 10]">
        Clique para adicionar uma pessoa
      </u-tooltip>
      <u-popover ref="popover" @hide="$refs.input.close()" class="task-person-input-popover">
        <pessoa-input @input="add" @close="$refs.popover.hide()" ref="input"/>
      </u-popover>
    </a>
  </div>
</template>
