export {getStatus} from '../../../utils/getStatus'

export const PRIORIDADE_BAIXA = 1;
export const PRIORIDADE_NORMAL = 2;
export const PRIORIDADE_ALTA  = 3;
export const PRIORIDADE_URGENTE = 4;

export const Prioridade = {
  [PRIORIDADE_BAIXA]: {title: 'Baixa', name: 'low', class: 'task-priority-' + PRIORIDADE_BAIXA},
  [PRIORIDADE_NORMAL]: {title: 'Normal', name: 'normal', class: 'task-priority-' + PRIORIDADE_NORMAL},
  [PRIORIDADE_ALTA]: {title: 'Alta', name: 'high', class: 'task-priority-' + PRIORIDADE_ALTA},
  [PRIORIDADE_URGENTE]: {title: 'Urgente', name: 'urgent', class: 'task-priority-' + PRIORIDADE_URGENTE},
}

export const STATUS_OPEN = 'open'
export const STATUS_PENDING = 'pending'
export const STATUS_IN_PROGRESS = 'in-progress'
export const STATUS_HOMOLOG = 'homolog'
export const STATUS_RECUSED = 'recused'
export const STATUS_CANCELED = 'canceled'
export const STATUS_COMPLETED = 'completed'

export const STATUS_INTERNAL_OPENED = 'opened'
export const STATUS_INTERNAL_CLOSED = 'closed'
