<script>
    import {UPopover} from 'uloc-vue'
    import PopoverUserProfile from "./popover-user-profile"
    import TaskRow from "./task-row"
    import GlobalPersonMixin from "@/components/pessoa/globalPersonMixin"
    import {completePart} from "@/domain/tarefas/services"
    export default {
      name: 'popover-user',
      inject: ['container'],
      mixins: [GlobalPersonMixin],
      props: ['user', 'task'],
      data () {
        return {
          router: this.container.$router
        }
      },
      computed: {},
      mounted () {
      },
      destroyed () {
      },
      methods: {
        entregar () {
          this.$uloc.dialog({
            title: 'Entregar trabalho',
            message: `Confirmar entrega do trabalho para o usuário selecionado ?`,
            noCaps: true,
            ok: `Sim`,
            cancel: 'Não'
          })
              .then(() => {
                completePart(this.task, this.user)
                    .then(response => {
                      console.log(response.data)
                    })
                    .catch(error => {
                      this.alertApiError(error)
                    })
              })
              .catch(() => {
              })
        }
      },
      components: {
        TaskRow,
        PopoverUserProfile,
        UPopover
      }
    }
</script>

<template>
    <u-popover class="popover-user" anchor="bottom middle" self="top middle" :offset="[8, 8]">
        <div class="wrapper">
            <div class="row">
                <div class="col-xs-6">
                    <div class="flex items-center">
                        <span class="cursor-pointer">
                            <span class="font-bold">{{user.person.name}}</span>
                            <popover-user-profile  anchor="center right" self="center left" :user="user"/>
                        </span>
                        <a @click="gotoPerson(user.person)" style="font-size: 10px" class="m-l-xs"><u-icon name="edit" type="fa" icon-style="light"/></a></div>
                    <div class="font-thin">#{{user.person.id}}</div>
                </div>
                <div class="col-xs-6 flex items-center justify-between">
                    <u-btn icon="timer" flat class="m-l-n">
                        <span class="m-l-sm">{{ user.workedHours }}</span>
                        <u-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
                            Tempo que {{user.person.name.split(' ').slice(0, -1).join('')}} trabalhou nesta tarefa
                        </u-tooltip>
                    </u-btn>
                    <u-btn @click="entregar" class="m-l" color="primary" flat noCaps>
                        Entregar parte
                        <u-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
                            Significa que {{user.person.name.split(' ').slice(0, -1).join('')}} terminou sua parte nesta tarefa
                        </u-tooltip>
                    </u-btn>
                </div>
            </div>
            <div class="row m-t m-b-n b-t padder-v window-task flex items-center">
                <div class="col-sm-3">
                    <task-row label="Esforço">
                      {{ user.expectedHours || '00:00' }} <!-- EDIT -->
                    </task-row>
                </div>
                <div class="col-sm-3">
                    <task-row label="Início">
                      <span style="font-size: 16px" v-if="user.startTime">{{ user.startTime|formatDate('dd/MM HH:mm') }}</span>
                      <span v-else>-</span>
                    </task-row>
                </div>
                <div class="col-sm-3">
                    <task-row label="Entrega">
                      <span style="font-size: 16px" v-if="user.deadline">{{ user.deadline|formatDate('dd/MM HH:mm') }}</span>
                      <span v-else>-</span>
                    </task-row>
                </div>
                <div class="col-sm-3">
                    <u-btn icon="delete" flat noCaps size="sm" @click="$emit('remove', user)">Remover alocação</u-btn>
                </div>
            </div>
        </div>
    </u-popover>
</template>
