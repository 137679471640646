<template>
  <task-row :label="label" col>
    <u-fake-loader v-if="loading" />
    <component v-bind:is="relation.entity" :value="dados" :relation="relation" :task="task" v-else>
      {{dados.label}}
    </component>
  </task-row>
</template>

<script>
import TaskRow from "@/components/sltarefas/components/tarefa/task-row"
import {getTaskRelationData} from "@/domain/tarefas/services"
import {UFakeLoader} from "uloc-vue"
import Vue from "vue"
import TaskPessoaInput from './RelationPessoaInput'
import TaskProcessoInput from './ProcessoInput'
import TaskBemInput from './BemInput'

Vue.component('pessoa', TaskPessoaInput)
Vue.component('processo', TaskProcessoInput)
Vue.component('bem', TaskBemInput)

export default {
  name: "TaskRelationInput",
  components: {TaskRow, UFakeLoader},
  props: {
    relation: {
      required: true
    },
    task: {
      required: true
    },
    isEditing: Boolean
  },
  data () {
    return {
      loading: true,
      dados: null
    }
  },
  mounted() {
    this.loading = true
    getTaskRelationData(this.task.id, this.relation.id)
    .then(response => {
      this.dados = response.data
      this.loading = false
    })
    .catch(error => {
      this.alertApiError(error)
      this.loading = false
    })
  },
  computed: {
    label () {
      switch (this.relation.entity) {
        case "pessoa":
          return 'Cliente'
        case "processo":
          return "Processo"
        case "bem":
          return "Bem"
      }
      return 'Relação não identificada'
    }
  }
}
</script>
