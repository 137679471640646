<script>
import {UChip, UTooltip, date} from 'uloc-vue'
import {WindowContent} from 'uloc-vue-plugin-erp'
// import TextEditor from "../utils/text-editor"
// import UserSelectPerson from "./inputs/UserSelect"
import TaskPriority from "./others/task-priority"
import TaskRow from "./tarefa/task-row"
import UProgress from "uloc-vue/src/components/progress/UProgress"

import TaskUsers from "./tarefa/task-users"
import PopoverUserProfile from "./tarefa/popover-user-profile"
import TaskTabs from "./tarefa/task-tabs"
import FollowTask from "./tarefa/follow-task"
import ButtonsTaskWindow from "./tarefa/window/buttons-task-window"
import {task} from "@/domain/tarefas/helpers/mock"
import DatetimeInput from '@/reuse/input/Datetime'
import {parse} from 'date-fns'
import HourInput from "@/components/sltarefas/components/inputs/HourInput"
import StatusTaskSelect from "@/components/sltarefas/components/inputs/StatusTaskSelect"
import TypeTaskSelect from "@/components/sltarefas/components/inputs/TypeTaskSelect"
import TaskPrioritySelect from "@/components/sltarefas/components/inputs/TaskPrioritySelect"
import SlEditor from "@/components/layout/components/Editor"
import {newTask, find, deleteTask, updateTask, work} from "@/domain/tarefas/services"
import {datetimeToEn} from '@/utils/date'
import {differenceInHours, parseISO, isToday} from 'date-fns'
import {Prioridade} from "@/domain/tarefas/helpers/TaskStatus"
import uploadareaMixin from "@/components/sltarefas/components/tarefa/attachments/uploadareaMixin"
import * as TaskStatus from "@/domain/tarefas/helpers/TaskStatus"
import TaskRelationInput from "@/components/sltarefas/components/inputs/RelationInput"
import globalPersonMixin from "@/components/pessoa/globalPersonMixin"
import globalProcessoMixin from "@/components/processos/globalProcessoMixin"
import GlobalPessoaInput from "@/components/pessoa/components/include/pessoa/PessoaInput"
import GlobalProcessoInput from "@/components/processos/components/processos/include/ProcessoInput"
import Work from "@/components/sltarefas/components/tarefa/work"
import WindowLoading from "@/components/layout/window/Loading"

export default {
  provide: function () {
    return {
      container: this
    }
  },
  name: 'tarefa',
  mixins: [uploadareaMixin, globalPersonMixin, globalProcessoMixin],
  components: {
    WindowLoading,
    Work,
    GlobalProcessoInput,
    GlobalPessoaInput,
    TaskRelationInput,
    TaskPrioritySelect,
    TypeTaskSelect,
    StatusTaskSelect,
    HourInput,
    ButtonsTaskWindow,
    FollowTask,
    TaskTabs,
    PopoverUserProfile,
    TaskUsers,
    UProgress,
    TaskRow,
    TaskPriority,
    // TextEditor,
    SlEditor,
    UChip,
    UTooltip,
    // UInput,
    DatetimeInput,
    WindowContent
  },
  props: ['id', '$router', 'entidade', 'entidadeId', 'cliente', 'processo', 'autoSelect', 'tags'],
  data() {
    const taskData = JSON.parse(JSON.stringify(task))
    taskData.entidade = this.entidade
    taskData.entidadeId = this.entidadeId
    if (this.cliente) {
      taskData.cliente = this.cliente // @deprecated
    }
    if (this.processo) {
      taskData.processo = this.processo // @deprecated
    }
    if (this.autoSelect) {
      if (this.autoSelect.cliente) {
        taskData.cliente = this.autoSelect.cliente
      }
      if (this.autoSelect.processo) {
        taskData.processo = this.autoSelect.processo
      }
    }
    if (Array.isArray(this.tags) && this.tags.length) {
      taskData.tags = this.tags
    }
    return {
      window: this.$root.wid,
      hideAll: false,
      loading: true,
      editing: false,
      textEditing: false,
      task: taskData,
      edit: {
        expectedHours: false
      },
      fake: {
        expectedHours: null
      },
      isDetailsMin: false,
      isDescMin: false
    }
  },
  computed: {
    isEditing() {
      return this.editing || (!this.id && !this.task.id)
    },
    taskPriority() {
      return Prioridade
    },
    isClosed() {
      if (!this.task || !this.task.status) {
        return false
      }
      return this.task.status.code > 89
    },
    hasParticipants() {
      return !!this.task.participants?.length
    }
  },
  mounted() {
    if (!this.id) {
      this.loading = false
      this.$nextTick(() => {
        this.$refs.taskNameElement.focus()
      })
    } else {
      this.load()
    }
  },
  destroyed() {
  },
  methods: {
    load() {
      this.loading = true
      find(this.id)
          .then(response => {
            console.log(response)
            if (!response.data.text) {
              response.data.text = ''
            }
            this.task = Object.assign({}, this.task, response.data)
            // this.task = response.data
            console.log(this.task)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    cancel() {
      this.$uloc.window.close(this.window)
    },
    transformToSend() {
      const task = JSON.parse(JSON.stringify(this.task))
      task.description = this.$refs.taskNameElement.textContent
      task.text = this.$refs.editor.htmlContent()
      if (task.deadline) {
        task.deadline = datetimeToEn(task.deadline)
      }
      if (task.startTime) {
        task.startTime = datetimeToEn(task.startTime)
      }
      if (task.participants && task.participants.length) {
        task.participants = task.participants.map(p => {
          return {
            person: p.person.id,
            user: p.user ? p.user.id : null,
            active: p.active
          }
        })
      }
      if (task.cliente && task.cliente.id) {
        task.cliente = task.cliente.id
      }
      if (task.processo && task.processo.id) {
        task.processo = task.processo.id
      }
      if (task.author && task.author.id) {
        task.author = task.author.id
      }
      if (task.priority && task.priority.id) {
        task.priority = task.priority.id
      }
      return task
    },
    transformToEdit() {
      const task = JSON.parse(JSON.stringify(this.task))
      setTimeout(() => {
        this.$refs.taskNameElement.innerHTML = task.description
      }, 100)
      if (task.deadline) {
        task.deadline = date.formatDate(task.deadline.date, 'DD/MM/YYYY HH:mm')
      }
      if (task.startTime) {
        task.startTime = date.formatDate(task.startTime.date, 'DD/MM/YYYY HH:mm')
      }
      if (task.status) {
        task.status = task.status.id
      }
      if (task.type) {
        task.type = task.type.id
      }
      if (task.project) {
        task.project = task.project.id
      }
      if (task.priority && task.priority.id) {
        task.priority = task.priority.id
      }
      return task
    },
    save() {
      const task = this.transformToSend()
      if (this.testConfig('task.obrigarSelecionarResponsavel', '1', false) && !this.hasParticipants) return this.$uloc.notify('Adicione pelo menos um responsável pela tarefa')
      this.loading = true
      const method = this.task.id ? updateTask : newTask
      let posEdit = (response) => {
        this.$nextTick(() => {
          this.editing = false
          this.hideAll = false
          this.$nextTick(() => {
            this.$refs.taskNameElement.innerText = response.data.description
          })
        })
      }
      method(task)
          .then(response => {
            console.log(response)
            this.loading = false
            this.$uloc.window.emit(this.window, 'created', response.data)
            this.hideAll = true
            if (!response.data.text) {
              response.data.text = ''
            }
            this.$uloc.notify({
              color: this.task.id ? 'black' : 'positive',
              message: this.task.id ? `Tarefa atualizada` : `Tarefa criada com id #${response.data.id}`,
              position: 'bottom-left',
            })
            this.task = response.data
            posEdit(response)
          })
          .catch(error => {
            this.loading = false
            posEdit({data: this.task})
            this.alertApiError(error)
          })
    },
    keydownDescription(evt) {
      if (evt.which === 13) {
        evt.preventDefault()
      }
      setTimeout(() => {
        this.task.description = this.$refs.taskNameElement.textContent || this.$refs.taskNameElement.innerText
      }, 20)
      // this.$refs[ref].innerHTML = this.$refs[ref].textContent || this.$refs[ref].innerText
    },
    parseDate(d) {
      if (!d) return
      if (d.date) {
        return d.date
      }
      let date
      try {
        if (String(d).length === 10 || String(d).length === 11) {
          date = parse(d, 'dd/MM/yyyy', new Date())
        } else if (String(d).length >= 16) {
          date = parse(d, 'dd/MM/yyyy HH:mm', new Date())
        } else {
          date = null
        }
      } catch (e) {
        date = null
      }
      return date
    },
    compareDate(d) {
      return Math.floor(differenceInHours(parseISO(d.date || d), new Date()) / 24)
    },
    isToday(d) {
      return isToday(parseISO(d.date || d))
    },
    colorize(d) {
      if (d.date) {
        d = d.date
      }
      const compare = this.compareDate(d)
      if (compare === 0) {
        return 'deep-orange'
      } else if (compare < 0) {
        return 'negative'
      } else {
        return 'positive'
      }
    },
    deleteTask() {
      this.confirmarExclusao('Tem certeza que deseja excluir esta tarefa? Todos os dados serão perdidos, sem possibilidade de recuperá-los.', 'excluir tarefa-' + this.task.id)
          .then(() => {
            this.$uloc.loading.show()
            deleteTask(this.task.id)
                .then(() => {
                  this.$uloc.loading.hide()
                  this.$uloc.notify({
                    color: 'positive',
                    message: `Tarefa excluída com sucesso.`
                  })
                  this.$uloc.window.emit(this.window, 'update')
                  this.$uloc.window.close(this.$root.wid)
                })
                .catch(error => {
                  this.$uloc.loading.hide()
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },
    startEditing() {
      this.hideAll = true
      this.task = this.transformToEdit()
      this.editing = true
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.hideAll = false
        })
      })
    },
    updateHour(field, val) {
      if (this.isEditing) return
      if (!this.task.id) return
      let task = {
        id: this.task.id
      }
      task[field] = val
      updateTask(task, 'PATCH')
          .then(response => {
            console.log(response)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    updateFollowers() {
      this.$refs.tabs.$emit('updateHistory')
    },
    finish() {
      if (!this.task.id) return
      let task = {
        id: this.task.id,
        statusCode: TaskStatus.STATUS_COMPLETED
      }
      updateTask(task, 'PATCH')
          .then(response => {
            console.log(response)
            this.task = response.data
            this.$uloc.window.emit(this.window, 'update', response.data)
            this.$refs.tabs.$emit('updateHistory')
          })
          .catch(error => {
            this.alertApiError(error)
          })
    }
  }
}
</script>

<template>
  <window-content v-if="!loading">
    <div class="w-m-header flex row justify-between full-width" :class="{'m-t': !$root.wid}">
      <h2 v-if="!task.id">Nova Tarefa</h2>
      <div class="col-grow-1" v-else>
        <span class="author" v-if="task.id">
        Tarefa #{{ task.id }} criada  por <a class="text-black">{{ task.createdByName }}
        <popover-user-profile :task="task" :user="task.author"/>
        </a> em {{ task.createdAt|formatDate }}
        </span>
      </div>
      <div>
        <buttons-task-window v-if="task.id" :task="task"/>
        <button v-if="$root.wid" @click="$uloc.window.close($root.wid)" type="button" class="btn-close">
          <i class="fal fa-times"/>
          <u-tooltip content-class="bg-indigo" :offset="[5, 5]">Fechar</u-tooltip>
        </button>
      </div>
    </div>
    <div class="w-m-body">
      <div v-if="!hideAll" class="task no-wrap h-full flex column content-between"
           :class="{'dropzoneEnter': dropzone, 'done': isClosed}"
           @dragenter.prevent.stop="dropzoneEnter"
           @dragover.prevent.stop="dropzone = true"
           @drop.prevent.stop="inputListener"
           @dragleave.prevent.stop="dropzone = false">
        <input @change="inputListener($event)" :accept="fileTypes" type="file" id="input" ref="input" multiple
               v-show="false">
        <div class="task-row1 flex row content-between" :class="{edit: task.id}">
          <div class="t-header flex-zero">
            <div class="flex items-center">
              <div v-if="$route && $route.name === 'task.show'" style="max-width: 100px" class="extendButtons"></div>
            </div>

            <div class="task-control m-t flex items-center">
              <work :task="task"/>

              <div class="col-grow-1 text-right">
                <div v-if="isClosed">
                  <u-chip color="green" square class="font-bold">
                    <i class="fas fa-check-circle m-r-xs"/> Concluída {{ task.closedAt|formatDate }}
                  </u-chip>
                </div>
                <div v-else-if="isEditing" class="task-date"></div>
                <div v-else-if="!isEditing && task.deadline" class="task-date">
                  <span class="m-l-xs">{{ task.deadline.date|formatDateDistance }}</span> <!-- color by date -->
                  <u-chip v-if="compareDate(task.deadline) !== 0" :color="colorize(task.deadline)" square
                          class="capitalize">
                    {{ task.deadline|formatDate('MMMM') }}, {{ task.deadline|formatDate('dd') }},
                    {{ task.deadline|formatDate('HH:mm') }}
                  </u-chip> <!-- click open calendar --> <!-- color by date -->
                  <u-chip color="deep-orange" v-else-if="isToday(task.deadline.date)" square class="capitalize">
                    Hoje, {{ task.deadline|formatDate('HH:mm') }}
                  </u-chip>
                  <u-chip color="deep-orange" v-else square class="capitalize">
                    Amanhã, {{ task.deadline|formatDate('HH:mm') }}
                  </u-chip>
                </div>
                <div v-else>
                  <u-chip color="grey-5" square>Sem previsão</u-chip>
                </div>
              </div>
            </div>

            <div v-if="isDetailsMin" class="task-body m-t m-b-lg pos-rlt">
              <u-icon @click.native="isDetailsMin = !isDetailsMin" :name="!isDetailsMin ? 'minus-square' : 'expand'"
                      class="cursor-pointer m-r pos-abt absolute-top-right m-t" type="fa" color="blue-grey-3">
                <u-tooltip :offset="[5,5]">{{ !isDetailsMin ? 'Ocultar' : 'Restaurar' }}</u-tooltip>
              </u-icon>
            </div>
            <div v-show="!isDetailsMin" class="task-body m-t e-input-modern basic label-size-2 size1 pos-rlt">
              <u-icon @click.native="isDetailsMin = !isDetailsMin" :name="!isDetailsMin ? 'minus-square' : 'expand'"
                      class="cursor-pointer m-r pos-abt absolute-top-right m-t" type="fa" color="blue-grey-3">
                <u-tooltip :offset="[5,5]">{{ !isDetailsMin ? 'Ocultar' : 'Restaurar' }}</u-tooltip>
              </u-icon>
              <div class="task-rows">
                <task-row label="Status" col>
                  <strong v-if="!isEditing" :class="'task-status-' + (task.status ? task.status.code : '0')">{{
                      task.status ? task.status.name : '-'
                    }}</strong>
                  <status-task-select :required="!!task.id" v-else no-label class="app-input" placeholder="Automático"
                                      v-model="task.status"/>
                </task-row>
                <!--<div class="task-row-col">
                    <label>Tipo</label>
                    <div class="content"><strong>
                         Criação &lt;!&ndash;<u-icon name="circle" type="fa" class="fa-fw vertical-top" color="positive"></u-icon>&ndash;&gt;
                    </strong></div>
                </div>-->
                <task-row label="Tipo" col>
                  <span v-if="!isEditing">{{ task.type ? task.type.name : '-' }}</span>
                  <type-task-select :required="!!task.id" v-else no-label class="app-input" v-model="task.type"/>
                </task-row>
                <task-row label="Prioridade" col>
                  <div v-if="!isEditing">
                    <span class="m-r-sm" :style="{color: task.priority.color}">{{ task.priority.name }}</span>
                    <task-priority v-if="task.priority && task.priority.order"
                                   :priority="taskPriority[task.priority.order].name"/>
                  </div>
                  <task-priority-select :required="!!task.id" v-else v-model="task.priority" no-label
                                        class="app-input"/>
                </task-row>
                <task-row v-if="false" label="Projeto" col>
                  <strong v-if="!task.project">Nenhum</strong> <!-- @TODO -->
                  <a v-else>
                    {{ task.project.name }}
                    <u-icon name="edit" type="fa" class="fa-fw vertical-top m-l-sm"/> <!-- @TODO -->
                  </a>
                </task-row>
                <task-relation-input v-for="r in task.entityRelations" :key="'relation' + r.id" :relation="r"
                                     :task="task" :is-editing="isEditing"/>
                <task-row label="Cliente" v-if="isEditing || task.cliente" col>
                  <a @click="gotoPerson(task.cliente)" class="font-bold"
                     v-if="!isEditing">{{ task.cliente && task.cliente.name }}</a>
                  <global-pessoa-input v-else v-model="task.cliente"/>
                </task-row>
                <task-row label="Processo" v-if="isEditing || task.processo" col>
                  <a @click="gotoProcesso(task.processo)" class="font-bold"
                     v-if="!isEditing">{{ task.processo && task.processo.numero | formataNumeroProcesso }}</a>
                  <global-processo-input v-else v-model="task.processo"/>
                </task-row>
                <task-row v-if="false" label="Tags" col class="m-b-none">
                  <!--<a class="m-r-sm">
                    <u-chip square dense>tag1</u-chip>
                  </a>
                  <a class="m-r-sm">
                    <u-chip square dense>tag2</u-chip>
                  </a>--> <!-- @TODO -->
                  -
                </task-row>
              </div>
              <div class="task-rows triple-column">
                <task-row label="Esforço total"
                          label-helper="Tempo necessário em horas para entregar a tarefa. Para adicionar minutos utilize h ou :">
                  <hour-input @update="(v) => updateHour('expectedHours', v)" v-model="task.expectedHours"/>
                </task-row>
                <task-row label="Tempo trabalhado"
                          label-helper="Soma das horas trabalhadas de todos os envolvidos na tarefa">
                  {{ task.workedHours }}
                </task-row>
                <task-row class="row-task-date" label="Início previsto"
                          label-helper="Data prevista para iniciar a tarefa">
                  <div v-if="!isEditing && task.startTime" class="uppercase">{{
                      task.startTime|formatDate('dd MMM yy')
                    }} <span>{{ task.startTime|formatDate('HH:mm') }}</span></div>
                  <div v-else-if="!isEditing && !task.startTime">-</div>
                  <div v-else>
                    <datetime-input input-class="app-input" date-type="datetime" date-format="##/##/#### ##:##"
                                    v-model.lazy="task.startTime"
                                    simpleBorder/>
                  </div>
                </task-row>
                <task-row class="row-task-date" label="Data de entrega"
                          label-helper="Data prevista para entregar a tarefa">
                  <div v-if="!isEditing && task.deadline" class="uppercase">{{
                      task.deadline|formatDate('dd MMM yy')
                    }} <span>{{ task.deadline|formatDate('HH:mm') }}</span></div>
                  <div v-else-if="!isEditing && !task.deadline">-</div>
                  <div v-else>
                    <!--                  <u-input class="app-input" hide-underline v-model="task.deadline" />-->
                    <datetime-input input-class="app-input" date-type="datetime" date-format="##/##/#### ##:##"
                                    v-model.lazy="task.deadline"
                                    simpleBorder/>
                  </div>
                  <div v-if="isEditing && !task.id">
                    <!--                  <u-chip dense square>Daqui um mês</u-chip>-->
                    <u-chip v-if="parseDate(task.deadline)" dense square>
                      {{ parseDate(task.deadline)|formatDateDistanceNoIso }}
                    </u-chip>
                  </div>
                  <!-- COLOCAR AVISO CASO O SISTEMA DETECTE QUE VAI ATRASAR -->
                </task-row>
                <task-row style="width: 66%; min-width: 66%; max-width: 66%" :label="'Progresso (' + task.progress + '%)'">
                  <template v-slot:label>
                    <label class="pos-rlt">
                      Progresso <span style="font-size: 10px" class="m">({{ task.progress }}%)</span>
                    </label>
                  </template>
                  <u-progress class="progress-sgrp m-r"
                              :percentage="task.progress"
                              color="positive" height="6px" style="border-radius: 5px; max-width: 100%"></u-progress>
                </task-row>
              </div>
            </div>

            <!--<div class="row m-t">
                <div class="col-sm-6">
                    <div class="task-body m-r-xs">
                        <div class="wrapper">
                            <h2>Responsáveis</h2>
                            <task-users :users="tarefa.responsaveis"/>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="task-body m-l-xs">
                        <div class="wrapper">a</div>
                    </div>
                </div>
            </div>-->

          </div>

          <div class="wrapper-lg flex w-full justify-between items-start" v-if="isDescMin">
            <div @click="isDescMin = !isDescMin" class="text-blue-grey-3 cursor-pointer"><i>Descrição ocultada, clique
              para restaurar</i></div>
            <u-icon @click.native="isDescMin = !isDescMin" :name="!isDescMin ? 'minus-square' : 'expand'"
                    class="cursor-pointer m-r" type="fa" color="blue-grey-3">
              <u-tooltip :offset="[5,5]">{{ !isDescMin ? 'Ocultar' : 'Restaurar' }}</u-tooltip>
            </u-icon>
          </div>
          <div class="task-right-items">
            <div class="right-task-title">
              <h1 class="taskTitle">
                <div class="hover-anchor cursor-pointer flex items-center">
                <span ref="taskNameElement" class="taskNameInput m-r-sm" tabindex="0"
                      :class="{'no-label': task.description}" :contenteditable="isEditing"
                      @keydown="keydownDescription">{{
                    !isEditing ? task.description : ''
                  }}</span>
                  <span v-if="isEditing" class="hover-action" style="font-size: 14px"><u-icon name="pen" type="fa"
                                                                                              icon-style="light"/></span>
                </div>
              </h1>
              <div class="m-b-xs m-t-xs" v-if="entidade">
                Tarefa relacionada à entidade <span class="capitalize">{{ entidade }}</span> com ID {{ entidadeId }}<span v-if="tags && tags.length">&nbsp;com a(s) tag(s): {{tags.join(', ')}}</span>
              </div>
            </div>
            <div class="right-users">
              <task-users is-user-component :disable-profile="true" :disable-add-user="isClosed"
                          :users="task.participants" :task="task"/>
            </div>
            <div class="task-description wrapper-lg" v-show="!isDescMin">
              <div class="flex w-full justify-between items-start">
                <div class="font-bold m-b-lg">Descrição</div>
<!--                <u-icon @click.native="isDescMin = !isDescMin" :name="!isDescMin ? 'minus-square' : 'expand'"
                        class="cursor-pointer m-r" type="fa" color="blue-grey-3">
                  <u-tooltip :offset="[5,5]">{{ !isDescMin ? 'Ocultar' : 'Restaurar' }}</u-tooltip>
                </u-icon>-->
              </div>

              <div style="margin: 0 -10px" class="doc-editor">
                <!--        <text-editor toolbar-position="top-outside"/>-->
                <sl-editor
                    ref="editor"
                    v-model="task.text"
                    placeholder="Faça uma anotação, @nome..."
                    :show-toolbar-on-focus="true"
                    :close-click-outside="false"
                    @focus="() => { textEditing = true }"
                />
              </div>
            </div>
          </div>
        </div>


        <task-tabs class="m-t-lg" ref="tabs" :task="task"/>

        <div class="task-footer">
          <div class="wrapper flex">
            <div v-if="task.followers" class="flex items-center">
              Seguidores:
              <task-users :task="task" is-follow-component disable-user-task disable-add-user :users="task.followers"
                          placeholder="Ninguém segue esta tarefa até o momento"/>
            </div>
            <div class="flex items-center col-grow justify-end">
              <follow-task @followersUpdate="updateFollowers" :task="task" v-if="!isEditing"/>
              <u-btn v-if="isEditing" @click="$uloc.window.close($root.wid)" :disable="loading"
                     class="app-btn m-r-sm text-black" no-caps
                     color="grey-3" label="Cancelar"/>
              <u-btn v-if="isEditing" @click="save" :disable="loading" class="app-btn" icon="save" no-caps
                     color="positive" label="Salvar"/>
            </div>
          </div>
        </div>

      </div>
    </div>
  </window-content>
  <window-loading v-else/>
</template>
