// @TODO: Poder editar ou cadastrar dado na tarefa
export default {
    inject: ['container'],
    components: {},
    props: {
        relation: {
            required: true
        },
        task: {
            required: true
        },
        value: {
            required: true
        },
        isEditing: Boolean
    },
    mounted() {
        if (this.$root && this.$root.wid) {
            this.router = this.container.$router
        }
    },
    computed: {
    }
}
